import fetch from 'auth/FetchInterceptor'

const exampleService = {}

exampleService.getPost = function (url, params) {
  return fetch({
    url: url,
    method: 'get',
    params
  })
}

exampleService.setPost = function (url, data) {
  return fetch({
    url: url,
    method: 'post',
    data: data
  })
}

exampleService.setDelete = function (url, params) {
  return fetch({
    url: url,
    method: 'delete',
    params
  })
}

exampleService.setDeleteData = function (url, data) {
  return fetch({
    url: url,
    method: 'delete',
    data: data
  })
}

exampleService.setEdit = function (url, data) {
  return fetch({
    url: url,
    method: 'put',
    data: data
  })
}

export default exampleService