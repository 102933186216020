import antdEnUS from 'antd/es/locale/tr_TR';
import enMsg from "../locales/tr_TR.json";

const TRLang = {
  antd: antdEnUS,
  locale: 'tr-TR',
  messages: {
    ...enMsg
  },
};
export default TRLang;
