import { Col, Row } from 'antd'
import i18next from 'i18next'
import moment from 'moment'
import React from 'react'
import { isMobile } from 'react-device-detect'

export default function Footer() {
	return (
		<>
			<Row className='mt-5' style={{ height: 1, backgroundColor: 'rgba(198, 213, 224,0.5)' }} ></Row>
			<Row gutter={32} className={isMobile ? 'center mt-4 ml-2 mr-2 mb-2' : 'mt-4 ml-2 mr-2 mb-2'}>
				<Col xs={24} sm={24} md={5} lg={5}>
					<img src="/img/logo.png" style={{ height: 45 }} />
					<p className='w-100 mt-3'>71-75 Shelton Street Covent Garden - <br />London / United Kingdom</p>
				</Col>
				<Col xs={24} sm={24} md={4} lg={4}>
					<h3 className='text-white'>{i18next.t("web.page_about")}</h3>
					<a className='gray-text-color' href="faq">{i18next.t("page.faq")}</a><br />
					<a className='gray-text-color' href="gdrp">{i18next.t("general.gdpr")}</a><br />
					<a className='gray-text-color' href="privacy">{i18next.t("general.privacy_and_security")}</a><br />
				</Col>
				<Col xs={24} sm={24} md={4} lg={4}>
					<h3 className='text-white'>{i18next.t("support.contact")}</h3>
					<a className='gray-text-color' href="mailto:support@juanapp.com">{i18next.t("web.page_contact")}: support@juanapp.com</a><br />
				</Col>
				<Col xs={24} sm={24} md={5} lg={5}>
					<h3 className='text-white'>{i18next.t("general.social_media")}</h3>
					<div className='alignCenter'>
						<a href="https://www.instagram.com/juanappcom" className='mr-4'><img src="/img/others/instagram.png" style={{ height: 40 }} /></a>
						<a href="https://www.youtube.com/channel/UC_rERqG7mIrQO_DXoiZcbMQ" className='mr-4'><img src="/img/others/youtube.png" style={{ height: 40 }} /></a>
						<a href="https://x.com/juanappcom?t=H-YzGFx1uFvVZmqigTv9CQ&s=08"><img src="/img/others/twitter.png" style={{ height: 40 }} /></a>
					</div>
				</Col>
				<Col xs={24} sm={24} md={6} lg={6}>
					<Row gutter={16} className={isMobile ? 'mt-3' : ''}>
						<Col xs={24} sm={24} md={24} lg={24}>
							<h3 className='text-white'>{i18next.t("web.get_the_app")}</h3>
						</Col>
						<Col xs={14} sm={14} md={12} lg={12} offset={isMobile ? 5 : 0}>
							<a href="https://play.google.com/store/apps/details?id=com.juan.app"><img src="/img/others/play-store.png" style={{ height: 60, width: '100%' }} /></a>
						</Col>
						<Col xs={14} sm={14} md={12} lg={12} offset={isMobile ? 5 : 0} className={isMobile ? 'mt-3' : ''}>
							<a href="https://apps.apple.com/us/app/juan/id6463897240"><img src="/img/others/app-store.png" style={{ height: 60, width: '100%' }} /></a>
						</Col>
						<Col xs={24} sm={24} md={24} lg={24} className='mt-3'>
							<img src="/img/others/payment-banner.png" className='br3' style={{ height: 75, width: '100%' }} />
						</Col>
					</Row>
				</Col>
			</Row>
			{/* <Row className='mt-5' style={{ height: 1, backgroundColor: 'rgba(198, 213, 224,0.5)' }} ></Row> */}
			{/* <Row className='mt-3 ml-2 mr-2'>
				<p>All you singles, listen up: if you’re looking to fall in love, want to start dating, ready to start a relationship, or want to keep it casual, you need to be on Tinder. With over 55 billion matches made, it’s the best free dating site to find your next best match. You’ve probably noticed; the dating landscape looks very different today, with most of us choosing to meet people online. With Tinder, the world’s most popular free dating app, you have millions of other singles at your fingertips, and they’re all ready to meet someone like you. Whether you’re straight or part of the LGBTQIA community, Tinder’s here to get the sparks flying.
					There really is something for everyone on Tinder. Looking for a relationship? You’ve got it. Want to make friends online? Say no more. Just started uni and want to make the most of your experience? Tinder U’s got you covered. Tinder isn’t your average dating site; it’s the most diverse dating app, where adults of all backgrounds and experiences are invited to make connections, memories and everything in between.</p>
			</Row> */}
			<Row className='mt-3' style={{ height: 1, backgroundColor: 'rgba(198, 213, 224,0.5)' }} ></Row>
			<Row className='mt-4 ml-2 mr-2'>
				<span className='ml-2'>© {moment(new Date())?.format('YYYY')} JES Technologies UK</span>
			</Row>
		</>
	)
}