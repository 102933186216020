import React, { useCallback } from "react";
import { Menu, Dropdown, Avatar, Calendar, Button, Row, Col } from "antd";
import { connect } from 'react-redux'
import { User, Setting2, Logout, AddSquare, Save2, Crown1, Shop, Note1, ShoppingCart, Calendar1, Bag2, Receipt, Notepad2, UserTick, Stickynote, Chart21, Location } from 'iconsax-react';
import i18next from "i18next";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import OneSignalReact from "react-onesignal";
const menuItem = [
  {
    title: "tab.customer_profile",
    Icon: User,
    path: "/profile"
  },
]

const signOut = () => {
  localStorage.clear();
  // OneSignalReact.removeExternalUserId();
  window.location.href = "/"
};

export const NavProfile = () => {
  const jwtToken = localStorage.getItem(AUTH_TOKEN)
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const profileImg = "/img/others/user.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div>
            <h4 className="mb-0">{userInfo?.fullname}</h4>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={"../profile-edit"} className="flex center">
                  <el.Icon variant="Broken" size="17" color="#ffffff" />
                  <span className="font-weight-normal ml-2">{i18next.t(el.title)}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
            <span className="flex center">
              <Logout variant="Outline" size="17" color="#ffffff" />
              <span className="font-weight-normal ml-2">{i18next.t("action.logout")}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      {
        jwtToken ?
          <>
            <div className="flex center mt-3 mr-3" style={{ border: '1px solid #B38F66', borderRadius: 32, height: 35, width: 35 }}>
              <a href="../favorites">
                <img src="/img/icons/favoriActive.svg" width={32} height={32} />
              </a>
            </div>
            <div className="flex center mt-3 mr-3" style={{ border: '1px solid #B38F66', borderRadius: 32, height: 35, width: 35 }}>
              <a href="../messages/all">
                <img src="/img/icons/messageBox.svg" width={32} height={32} />
              </a>
            </div>
            <div className="flex center mt-3 mr-3" style={{ border: '1px solid #B38F66', borderRadius: 32, height: 35, width: 35 }}>
              <a href="../notification">
                <img src="/img/icons/notificationActive.svg" width={32} height={32} />
              </a>
            </div>
            <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
              <div className="d-flex align-item-center cursor-pointer">
                <div className="flex center">
                  <User className="dark-gray-color" size={18} />
                  {!isMobile && <h5 className="mb-0 ml-2">{userInfo?.fullname}</h5>}
                </div>
              </div>
            </Dropdown >
          </>
          :
          <Row className="mr-4">
            {
              !isMobile &&
              <>
                <Col xs={12} sm={12} md={10}>
                  <Link to="/login"><Button className={'button-banner pl-2 pr-2'}><b className='text-primary'>{i18next.t("web.home_loginbtn1")}</b></Button></Link>
                </Col>
                <Col xs={12} sm={12} md={7}>
                  <Link to="/login"><Button className={isMobile ? 'button-gold pl-2 pr-2' : 'button-gold pl-4 pr-4'}><b className='text-primary'>{i18next.t("web.login")}</b></Button></Link>
                </Col>
                <Col xs={12} sm={12} md={7}>
                  <Link to="/register"><Button className={isMobile ? 'button-primary pl-2 pr-2' : 'button-primary pl-4 pr-4'}><b className='text-gold'>{i18next.t("web.register")}</b></Button></Link>
                </Col>
              </>
            }
          </Row>
      }
    </>
  );
}

export default connect(null, {})(NavProfile)
