import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { Actions as RootActions } from "redux/root/index"
import i18next from "i18next";
import ExampleService from "services/ExampleService";
import { useThemeSwitcher } from "react-css-theme-switcher";
// import OneSignal from 'react-onesignal';
import { ONE_SIGNAL_APP_ID } from 'configs/AppConfig';

const LoadApp = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // OneSignal.init({ appId: ONE_SIGNAL_APP_ID });
    let theme = localStorage.getItem("theme");
    let languageId = localStorage.getItem("languageId");
    let languageShortName = localStorage.getItem("languageShortName");
    if (!languageId) {
      languageId = navigator.language == "tr-TR" ? 1 : 2;
      localStorage.setItem("languageId", languageId);
      localStorage.setItem("languageShortName", navigator.language == "tr-TR" ? "tr" : "en");
      window.location.reload();
    }

    ExampleService.setPost("dataTranslate/dropdown/" + languageId, null).then(resp => {
      var list = resp.data?.map((item) => {
        return (`"${item.keyword}" : "${(item.value + "").replace(/"/g, '\\"')}"`);
      });
      var lng = "lng" + Math.random();
      var translateData = JSON.parse('{"' + languageShortName + '"' + ': {"translation": {' + list.join(",") + '}}} ');
      i18next.init({
        lng: languageShortName,
        debug: false,
        resources: translateData,
        keySeparator: true
      });
      dispatch(RootActions.setRootState({
        i18nextLoading: false
      }));
    }).catch(e => {
      if (e) console.log(e);
    })

    const jwtToken = localStorage.getItem(AUTH_TOKEN)
    if (jwtToken) {
      ExampleService.getPost("auth/userInfo", null).then(resp => {
        localStorage.setItem("userinfo", JSON.stringify(resp.data?.user));
      }).catch(e => {
        if (e) console.log(e);
      })
    }
    dispatch(RootActions.setRootState({
      appIsLoading: false
    }));
  }, [])

  return null;
}

export default LoadApp;