import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from './Auth';
import Theme from './Theme';
import * as root from "./Root"

export default history =>
    combineReducers({
        router: connectRouter(history),
        theme: Theme,
        auth: Auth,
        root: root.reducer,
    });