import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import ThemeConfigurator from './ThemeConfigurator';
import { connect } from "react-redux";
import { DIR_RTL } from 'constants/ThemeConstant';
import { Setting2 } from 'iconsax-react';
import i18next from 'i18next';

export class NavPanel extends Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <div className="flex center ml-4 cursor-pointer" onClick={this.showDrawer}>
          <Setting2 size={18} />
          <span className="mb-0 ml-2">{i18next.t("general.settings")}</span>
        </div>
        <Drawer
          title={i18next.t("general.settings")}
          placement={this.props.direction === DIR_RTL ? 'left' : 'right'}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);