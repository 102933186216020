import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={'/home'} component={lazy(() => import('./home'))} />
        <Route path={'/ayarlar'} component={lazy(() => import('./settings'))} />
        <Route path={'/profile'} component={lazy(() => import('./profile'))} />
        <Route path={'/redirect-profile/:username'} component={lazy(() => import('./redirect-profile'))} />
        <Route path={'/worker-profile/:username'} component={lazy(() => import('./worker-profile'))} />
        <Route path={'/customer-profile/:username'} component={lazy(() => import('./customer-profile'))} />
        <Route path={'/faq'} component={lazy(() => import('./shared/faq'))} />
        <Route path={'/what-is-juan'} component={lazy(() => import('./shared/what-is-juan'))} />
        <Route path={'/profile-edit'} component={lazy(() => import('./shared/profile-edit'))} />
        <Route path={'/language'} component={lazy(() => import('./shared/language'))} />
        <Route path={'/seller-juan'} component={lazy(() => import('./shared/seller-juan'))} />
        <Route path={'/worker-balance'} component={lazy(() => import('./shared/worker-balance'))} />
        <Route path={'/customer-balance'} component={lazy(() => import('./shared/customer-balance'))} />
        <Route path={'/feedback'} component={lazy(() => import('./shared/feedback'))} />
        <Route path={'/report/:type/:id'} component={lazy(() => import('./shared/report'))} />
        <Route path={'/support'} component={lazy(() => import('./shared/support'))} />
        <Route path={'/notification'} component={lazy(() => import('./shared/notification'))} />
        <Route path={'/messages/:id'} component={lazy(() => import('./shared/messages'))} />
        <Route path={'/my-ads'} component={lazy(() => import('./shared/my-ads'))} />
        <Route path={'/new-ads'} component={lazy(() => import('./shared/new-ads'))} />
        <Route path={'/advert-detail/:id'} component={lazy(() => import('./shared/advert-detail'))} />
        <Route path={'/favorites'} component={lazy(() => import('./shared/favorites'))} />
        <Route path={'/order-history'} component={lazy(() => import('./shared/order-history'))} />
        <Route path={'/order-detail/:id'} component={lazy(() => import('./shared/order-detail'))} />
        <Route path={'/contact'} component={lazy(() => import('./contact'))} />
        <Route path={'/privacy'} component={lazy(() => import('./privacy'))} />
        <Route path={'/gdrp'} component={lazy(() => import('./kvkk'))} />
        <Route path={'/hakkimizda'} component={lazy(() => import('./about-us'))} />
        <Route path={'/forgot-password/:type'} component={lazy(() => import(`./authentication/forgot-password`))} />
        <Route path={'/login'} component={lazy(() => import('./authentication/login-page'))} />
        <Route path={'/login-phone'} component={lazy(() => import('./authentication/login-phone'))} />
        <Route path={'/login-mail'} component={lazy(() => import('./authentication/login-mail'))} />
        <Route path={'/login-otp/:type'} component={lazy(() => import('./authentication/login-otp'))} />
        <Route path={'/register-complete'} component={lazy(() => import('./authentication/register-complete'))} />
        <Route path={'/after-register'} component={lazy(() => import('./authentication/after-register'))} />
        <Route path={'/register'} component={lazy(() => import('./authentication/register-page'))} />
        <Route path={'/register-phone'} component={lazy(() => import('./authentication/register-phone'))} />
        <Route path={'/register-mail'} component={lazy(() => import('./authentication/register-mail'))} />
        <Route path={'/register-otp/:type'} component={lazy(() => import('./authentication/register-otp'))} />
        <Redirect from={'/'} to={'/home'} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);