import React from 'react';
import { Provider } from 'react-redux';
import { createHashHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import LoadApp from 'load-app';
import reducers from 'redux/reducers';
import ScrollToTop from 'components/layout-components/ScrollToTop';
// import store from 'redux/store';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const history = createHashHistory()
  const sagaMiddleware = createSagaMiddleware()
  const routeMiddleware = routerMiddleware(history)
  const middlewares = [sagaMiddleware, routeMiddleware]
  const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
  return (
    <div className="App">
      <Provider store={store}>
        <LoadApp />
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
