import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Juan';
export const COMMISSION_PERCENT = 0.02;
export const COPYRIGHT_NAME = '';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const WEB_SOCKET_URL = env.WEB_SOCKET_URL
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const ONE_SIGNAL_APP_ID = '0991980f-166e-4632-a9f4-a7904784f494';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'tr',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#fff',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'dark',
	direction: DIR_LTR
};
