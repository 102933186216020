const dashBoardNavTree = [{
  key: 'home',
  path: `/home`,
  title: 'general.home',
  breadcrumb: false,
  submenu: []
}]

const blogs = [{
  key: 'blogs',
  path: `/bloglar`,
  title: 'general.blogs',
  breadcrumb: false,
  submenu: []
}]

let navigationConfig = [];
navigationConfig.push(...dashBoardNavTree);
navigationConfig.push(...blogs);

export default navigationConfig;