import React from "react";
import { CheckOutlined, GlobalOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import lang from "assets/data/language.data.json";
import { connect } from "react-redux";
import { onLocaleChange } from 'redux/actions/Theme'
import exampleService from "services/ExampleService";
import useRefState from "utils/use-ref-state";
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { useEffect } from "react";
import { useCallback } from "react";
import { Global } from "iconsax-react";

function getLanguageDetail(locale) {
	const data = lang.filter(elm => (elm.langId === locale))
	return data[0]
}

const SelectedLanguage = ({ locale }) => {
	const language = getLanguageDetail(locale)
	const { langName, icon } = language
	return (
		<div className="d-flex align-items-center">
			<img style={{ maxWidth: '20px' }} src={`/img/flags/${icon}.png`} alt={langName} />
			<span className="font-weight-semibold ml-2">{langName} <DownOutlined className="font-size-xs" /></span>
		</div>
	)
}

export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {
	const [languageArray, languageArrayRef, setLanguageArray] = useRefState(null);
	const ChangeLanguage = useCallback((langId, shortName) => {
		console.log("langId", langId);
		const jwtToken = localStorage.getItem(AUTH_TOKEN)
		if (jwtToken) {
			exampleService.setEdit("User/changeLanguage/" + langId, null).then(resp => {
				localStorage.setItem("languageId", langId);
				localStorage.setItem("languageShortName", shortName);
				window.location.reload();
			}).catch(e => {
				e && console.log(e);
			});
		}
		else {
			localStorage.setItem("languageId", langId);
			localStorage.setItem("languageShortName", shortName);
			window.location.reload();
		}
	}, []);

	const LanguageList = useCallback(() => {
		const languageId = navigator.language == "tr-TR" ? 1 : 2;
		exampleService.setPost("language/dropdown", { languageId: languageId }).then(resp => {
			setLanguageArray(resp.data);
		}).catch(e => {
			e && console.log(e);
			setLanguageArray([]);
		})
	}, []);

	useEffect(() => {
		LanguageList();
	}, []);

	const language = getLanguageDetail(locale)
	const localeLangId = ("languageId");
	let languageList = languageArray ? languageArray : languageArrayRef.current;
	const languageOption = (
		<Menu>
			{
				languageList?.map((elm, i) => {
					return (
						<Menu.Item
							key={i}
							className={localeLangId == elm.id ? 'ant-dropdown-menu-item-active' : ''}
							onClick={() => ChangeLanguage(elm.id, elm.shortName.toLowerCase())}
						>
							<span className="d-flex justify-content-between align-items-center">
								<div>
									<img style={{ maxWidth: '20px' }} src={`/img/flags/${elm.shortName.toLowerCase()}.png`} alt={elm.langName} />
									<span className="font-weight-normal ml-2">{elm.name}</span>
								</div>
								{localeLangId == elm.id ? <CheckOutlined className="text-success" /> : null}
							</span>
						</Menu.Item>
					)
				})
			}
		</Menu >
	)
	return (
		<Dropdown placement="bottomCenter" className="center" overlay={languageOption} trigger={["click"]}>
			{
				configDisplay ?
					(
						<a href="#/" className="text-gray" onClick={e => e.preventDefault()}>
							<SelectedLanguage className="mt-2" locale={locale} />
						</a>
					)
					:
					(
						<Menu mode="horizontal">
							<Menu.Item key="language">
								<a href="#/" onClick={e => e.preventDefault()}>
									<Global className="flex center nav-icon mr-0" />
								</a>
							</Menu.Item>
						</Menu>
					)
			}
		</Dropdown>
	)
}

const mapStateToProps = ({ theme }) => {
	const { locale } = theme;
	return { locale }
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
